var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('base-card',[_c('v-card-title',[_vm._v("Transactions")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"24"}},[_c('img',{attrs:{"src":item.name,"alt":""}})])]}},{key:"item.desc",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.desc)+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0 font-weight-bold",class:item.amountColor},[_vm._v(" "+_vm._s(item.amount)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.statusColor},[_vm._v(" "+_vm._s(item.status)+" ")])]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('base-card',[_c('v-card-title',[_vm._v("Files")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 eg-table-bnone eg-table-td-py-md",attrs:{"hide-default-footer":"","headers":_vm.headersTwo,"items":_vm.dessertsTwo},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"46"}},[_c('img',{attrs:{"src":item.name,"alt":""}})])]}},{key:"item.folderName",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.folderName)+" ")])]}},{key:"item.member",fn:function(ref){
var item = ref.item;
return [_c('avatarGroupCard')]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":""}},on),[_c('v-icon',{},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){}}},[_c('v-icon',{staticClass:"text-body-1 mr-2"},[_vm._v(" mdi-eye-off ")]),_c('v-list-item-title',[_vm._v(" Unpublish ")])],1),_c('v-list-item',{on:{"click":function($event){}}},[_c('v-icon',{staticClass:"text-body-1 mr-2"},[_vm._v(" mdi-lead-pencil ")]),_c('v-list-item-title',[_vm._v(" Edit Job Post ")])],1),_c('v-list-item',{on:{"click":function($event){}}},[_c('v-icon',{staticClass:"text-body-1 mr-2"},[_vm._v(" mdi-trash-can ")]),_c('v-list-item-title',[_vm._v(" Delete ")])],1)],1)],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('base-card',[_c('v-card-title',[_vm._v("Enrolled Courses")]),_c('v-card-text',[_c('v-data-table',{staticClass:"eg-table-td-py-md",attrs:{"hide-default-footer":"","headers":_vm.headersThree,"items":_vm.dessertsThree},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"24"}},[_c('img',{attrs:{"src":item.name,"alt":""}})])]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.course)+" ")])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":item.progress}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"warning"}},[_c('v-icon',[_vm._v(" mdi-play ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }